export const Timezones = [
  {
    name: 'Africa/Abidjan',
    offset: 0
  },
  {
    name: 'Africa/Accra',
    offset: 0
  },
  {
    name: 'Africa/Addis_Ababa',
    offset: 3
  },
  {
    name: 'Africa/Algiers',
    offset: 1
  },
  {
    name: 'Africa/Asmara',
    offset: 3
  },
  {
    name: 'Africa/Asmera',
    offset: 3
  },
  {
    name: 'Africa/Bamako',
    offset: 0
  },
  {
    name: 'Africa/Bangui',
    offset: 1
  },
  {
    name: 'Africa/Banjul',
    offset: 0
  },
  {
    name: 'Africa/Bissau',
    offset: 0
  },
  {
    name: 'Africa/Blantyre',
    offset: 2
  },
  {
    name: 'Africa/Brazzaville',
    offset: 1
  },
  {
    name: 'Africa/Bujumbura',
    offset: 2
  },
  {
    name: 'Africa/Cairo',
    offset: 2
  },
  {
    name: 'Africa/Casablanca',
    offset: 0
  },
  {
    name: 'Africa/Ceuta',
    offset: 1
  },
  {
    name: 'Africa/Conakry',
    offset: 0
  },
  {
    name: 'Africa/Dakar',
    offset: 0
  },
  {
    name: 'Africa/Dar_es_Salaam',
    offset: 3
  },
  {
    name: 'Africa/Djibouti',
    offset: 3
  },
  {
    name: 'Africa/Douala',
    offset: 1
  },
  {
    name: 'Africa/El_Aaiun',
    offset: 0
  },
  {
    name: 'Africa/Freetown',
    offset: 0
  },
  {
    name: 'Africa/Gaborone',
    offset: 2
  },
  {
    name: 'Africa/Harare',
    offset: 2
  },
  {
    name: 'Africa/Johannesburg',
    offset: 2
  },
  {
    name: 'Africa/Juba',
    offset: 2
  },
  {
    name: 'Africa/Kampala',
    offset: 3
  },
  {
    name: 'Africa/Khartoum',
    offset: 2
  },
  {
    name: 'Africa/Kigali',
    offset: 2
  },
  {
    name: 'Africa/Kinshasa',
    offset: 1
  },
  {
    name: 'Africa/Lagos',
    offset: 1
  },
  {
    name: 'Africa/Libreville',
    offset: 1
  },
  {
    name: 'Africa/Lome',
    offset: 0
  },
  {
    name: 'Africa/Luanda',
    offset: 1
  },
  {
    name: 'Africa/Lubumbashi',
    offset: 2
  },
  {
    name: 'Africa/Lusaka',
    offset: 2
  },
  {
    name: 'Africa/Malabo',
    offset: 1
  },
  {
    name: 'Africa/Maputo',
    offset: 2
  },
  {
    name: 'Africa/Maseru',
    offset: 2
  },
  {
    name: 'Africa/Mbabane',
    offset: 2
  },
  {
    name: 'Africa/Mogadishu',
    offset: 3
  },
  {
    name: 'Africa/Monrovia',
    offset: 0
  },
  {
    name: 'Africa/Nairobi',
    offset: 3
  },
  {
    name: 'Africa/Ndjamena',
    offset: 1
  },
  {
    name: 'Africa/Niamey',
    offset: 1
  },
  {
    name: 'Africa/Nouakchott',
    offset: 0
  },
  {
    name: 'Africa/Ouagadougou',
    offset: 0
  },
  {
    name: 'Africa/Porto-Novo',
    offset: 1
  },
  {
    name: 'Africa/Sao_Tome',
    offset: 0
  },
  {
    name: 'Africa/Timbuktu',
    offset: 0
  },
  {
    name: 'Africa/Tripoli',
    offset: 2
  },
  {
    name: 'Africa/Tunis',
    offset: 1
  },
  {
    name: 'Africa/Windhoek',
    offset: 1
  },
  {
    name: 'America/Adak',
    offset: -10
  },
  {
    name: 'America/Anchorage',
    offset: -9
  },
  {
    name: 'America/Anguilla',
    offset: -4
  },
  {
    name: 'America/Antigua',
    offset: -4
  },
  {
    name: 'America/Araguaina',
    offset: -3
  },
  {
    name: 'America/Argentina/Buenos_Aires',
    offset: -3
  },
  {
    name: 'America/Argentina/Catamarca',
    offset: -3
  },
  {
    name: 'America/Argentina/ComodRivadavia',
    offset: -3
  },
  {
    name: 'America/Argentina/Cordoba',
    offset: -3
  },
  {
    name: 'America/Argentina/Jujuy',
    offset: -3
  },
  {
    name: 'America/Argentina/La_Rioja',
    offset: -3
  },
  {
    name: 'America/Argentina/Mendoza',
    offset: -3
  },
  {
    name: 'America/Argentina/Rio_Gallegos',
    offset: -3
  },
  {
    name: 'America/Argentina/Salta',
    offset: -3
  },
  {
    name: 'America/Argentina/San_Juan',
    offset: -3
  },
  {
    name: 'America/Argentina/San_Luis',
    offset: -3
  },
  {
    name: 'America/Argentina/Tucuman',
    offset: -3
  },
  {
    name: 'America/Argentina/Ushuaia',
    offset: -3
  },
  {
    name: 'America/Aruba',
    offset: -4
  },
  {
    name: 'America/Asuncion',
    offset: -4
  },
  {
    name: 'America/Atikokan',
    offset: -5
  },
  {
    name: 'America/Atka',
    offset: -10
  },
  {
    name: 'America/Bahia',
    offset: -3
  },
  {
    name: 'America/Bahia_Banderas',
    offset: -6
  },
  {
    name: 'America/Barbados',
    offset: -4
  },
  {
    name: 'America/Belem',
    offset: -3
  },
  {
    name: 'America/Belize',
    offset: -6
  },
  {
    name: 'America/Blanc-Sablon',
    offset: -4
  },
  {
    name: 'America/Boa_Vista',
    offset: -4
  },
  {
    name: 'America/Bogota',
    offset: -5
  },
  {
    name: 'America/Boise',
    offset: -7
  },
  {
    name: 'America/Buenos_Aires',
    offset: -3
  },
  {
    name: 'America/Cambridge_Bay',
    offset: -7
  },
  {
    name: 'America/Campo_Grande',
    offset: -4
  },
  {
    name: 'America/Cancun',
    offset: -5
  },
  {
    name: 'America/Caracas',
    offset: -4
  },
  {
    name: 'America/Catamarca',
    offset: -3
  },
  {
    name: 'America/Cayenne',
    offset: -3
  },
  {
    name: 'America/Cayman',
    offset: -5
  },
  {
    name: 'America/Chicago',
    offset: -6
  },
  {
    name: 'America/Chihuahua',
    offset: -7
  },
  {
    name: 'America/Coral_Harbour',
    offset: -5
  },
  {
    name: 'America/Cordoba',
    offset: -3
  },
  {
    name: 'America/Costa_Rica',
    offset: -6
  },
  {
    name: 'America/Creston',
    offset: -7
  },
  {
    name: 'America/Cuiaba',
    offset: -4
  },
  {
    name: 'America/Curacao',
    offset: -4
  },
  {
    name: 'America/Danmarkshavn',
    offset: 0
  },
  {
    name: 'America/Dawson',
    offset: -7
  },
  {
    name: 'America/Dawson_Creek',
    offset: -7
  },
  {
    name: 'America/Denver',
    offset: -7
  },
  {
    name: 'America/Detroit',
    offset: -5
  },
  {
    name: 'America/Dominica',
    offset: -4
  },
  {
    name: 'America/Edmonton',
    offset: -7
  },
  {
    name: 'America/Eirunepe',
    offset: -5
  },
  {
    name: 'America/El_Salvador',
    offset: -6
  },
  {
    name: 'America/Ensenada',
    offset: -8
  },
  {
    name: 'America/Fort_Nelson',
    offset: -7
  },
  {
    name: 'America/Fort_Wayne',
    offset: -5
  },
  {
    name: 'America/Fortaleza',
    offset: -3
  },
  {
    name: 'America/Glace_Bay',
    offset: -4
  },
  {
    name: 'America/Godthab',
    offset: -3
  },
  {
    name: 'America/Goose_Bay',
    offset: -4
  },
  {
    name: 'America/Grand_Turk',
    offset: -5
  },
  {
    name: 'America/Grenada',
    offset: -4
  },
  {
    name: 'America/Guadeloupe',
    offset: -4
  },
  {
    name: 'America/Guatemala',
    offset: -6
  },
  {
    name: 'America/Guayaquil',
    offset: -5
  },
  {
    name: 'America/Guyana',
    offset: -4
  },
  {
    name: 'America/Halifax',
    offset: -4
  },
  {
    name: 'America/Havana',
    offset: -5
  },
  {
    name: 'America/Hermosillo',
    offset: -7
  },
  {
    name: 'America/Indiana/Indianapolis',
    offset: -5
  },
  {
    name: 'America/Indiana/Knox',
    offset: -6
  },
  {
    name: 'America/Indiana/Marengo',
    offset: -5
  },
  {
    name: 'America/Indiana/Petersburg',
    offset: -5
  },
  {
    name: 'America/Indiana/Tell_City',
    offset: -6
  },
  {
    name: 'America/Indiana/Vevay',
    offset: -5
  },
  {
    name: 'America/Indiana/Vincennes',
    offset: -5
  },
  {
    name: 'America/Indiana/Winamac',
    offset: -5
  },
  {
    name: 'America/Indianapolis',
    offset: -5
  },
  {
    name: 'America/Inuvik',
    offset: -7
  },
  {
    name: 'America/Iqaluit',
    offset: -5
  },
  {
    name: 'America/Jamaica',
    offset: -5
  },
  {
    name: 'America/Jujuy',
    offset: -3
  },
  {
    name: 'America/Juneau',
    offset: -9
  },
  {
    name: 'America/Kentucky/Louisville',
    offset: -5
  },
  {
    name: 'America/Kentucky/Monticello',
    offset: -5
  },
  {
    name: 'America/Knox_IN',
    offset: -6
  },
  {
    name: 'America/Kralendijk',
    offset: -4
  },
  {
    name: 'America/La_Paz',
    offset: -4
  },
  {
    name: 'America/Lima',
    offset: -5
  },
  {
    name: 'America/Los_Angeles',
    offset: -8
  },
  {
    name: 'America/Louisville',
    offset: -5
  },
  {
    name: 'America/Lower_Princes',
    offset: -4
  },
  {
    name: 'America/Maceio',
    offset: -3
  },
  {
    name: 'America/Managua',
    offset: -6
  },
  {
    name: 'America/Manaus',
    offset: -4
  },
  {
    name: 'America/Marigot',
    offset: -4
  },
  {
    name: 'America/Martinique',
    offset: -4
  },
  {
    name: 'America/Matamoros',
    offset: -6
  },
  {
    name: 'America/Mazatlan',
    offset: -7
  },
  {
    name: 'America/Mendoza',
    offset: -3
  },
  {
    name: 'America/Menominee',
    offset: -6
  },
  {
    name: 'America/Merida',
    offset: -6
  },
  {
    name: 'America/Metlakatla',
    offset: -9
  },
  {
    name: 'America/Mexico_City',
    offset: -6
  },
  {
    name: 'America/Miquelon',
    offset: -3
  },
  {
    name: 'America/Moncton',
    offset: -4
  },
  {
    name: 'America/Monterrey',
    offset: -6
  },
  {
    name: 'America/Montevideo',
    offset: -3
  },
  {
    name: 'America/Montreal',
    offset: -5
  },
  {
    name: 'America/Montserrat',
    offset: -4
  },
  {
    name: 'America/Nassau',
    offset: -5
  },
  {
    name: 'America/New_York',
    offset: -5
  },
  {
    name: 'America/Nipigon',
    offset: -5
  },
  {
    name: 'America/Nome',
    offset: -9
  },
  {
    name: 'America/Noronha',
    offset: -2
  },
  {
    name: 'America/North_Dakota/Beulah',
    offset: -6
  },
  {
    name: 'America/North_Dakota/Center',
    offset: -6
  },
  {
    name: 'America/North_Dakota/New_Salem',
    offset: -6
  },
  {
    name: 'America/Nuuk',
    offset: -3
  },
  {
    name: 'America/Ojinaga',
    offset: -7
  },
  {
    name: 'America/Panama',
    offset: -5
  },
  {
    name: 'America/Pangnirtung',
    offset: -5
  },
  {
    name: 'America/Paramaribo',
    offset: -3
  },
  {
    name: 'America/Phoenix',
    offset: -7
  },
  {
    name: 'America/Port-au-Prince',
    offset: -5
  },
  {
    name: 'America/Port_of_Spain',
    offset: -4
  },
  {
    name: 'America/Porto_Acre',
    offset: -5
  },
  {
    name: 'America/Porto_Velho',
    offset: -4
  },
  {
    name: 'America/Puerto_Rico',
    offset: -4
  },
  {
    name: 'America/Punta_Arenas',
    offset: -3
  },
  {
    name: 'America/Rainy_River',
    offset: -6
  },
  {
    name: 'America/Rankin_Inlet',
    offset: -6
  },
  {
    name: 'America/Recife',
    offset: -3
  },
  {
    name: 'America/Regina',
    offset: -6
  },
  {
    name: 'America/Resolute',
    offset: -6
  },
  {
    name: 'America/Rio_Branco',
    offset: -5
  },
  {
    name: 'America/Rosario',
    offset: -3
  },
  {
    name: 'America/Santa_Isabel',
    offset: -8
  },
  {
    name: 'America/Santarem',
    offset: -3
  },
  {
    name: 'America/Santiago',
    offset: -4
  },
  {
    name: 'America/Santo_Domingo',
    offset: -4
  },
  {
    name: 'America/Sao_Paulo',
    offset: -3
  },
  {
    name: 'America/Scoresbysund',
    offset: -1
  },
  {
    name: 'America/Shiprock',
    offset: -7
  },
  {
    name: 'America/Sitka',
    offset: -9
  },
  {
    name: 'America/St_Barthelemy',
    offset: -4
  },
  {
    name: 'America/St_Johns',
    offset: -3
  },
  {
    name: 'America/St_Kitts',
    offset: -4
  },
  {
    name: 'America/St_Lucia',
    offset: -4
  },
  {
    name: 'America/St_Thomas',
    offset: -4
  },
  {
    name: 'America/St_Vincent',
    offset: -4
  },
  {
    name: 'America/Swift_Current',
    offset: -6
  },
  {
    name: 'America/Tegucigalpa',
    offset: -6
  },
  {
    name: 'America/Thule',
    offset: -4
  },
  {
    name: 'America/Thunder_Bay',
    offset: -5
  },
  {
    name: 'America/Tijuana',
    offset: -8
  },
  {
    name: 'America/Toronto',
    offset: -5
  },
  {
    name: 'America/Tortola',
    offset: -4
  },
  {
    name: 'America/Vancouver',
    offset: -8
  },
  {
    name: 'America/Virgin',
    offset: -4
  },
  {
    name: 'America/Whitehorse',
    offset: -7
  },
  {
    name: 'America/Winnipeg',
    offset: -6
  },
  {
    name: 'America/Yakutat',
    offset: -9
  },
  {
    name: 'America/Yellowknife',
    offset: -7
  },
  {
    name: 'Antarctica/Casey',
    offset: 11
  },
  {
    name: 'Antarctica/Davis',
    offset: 7
  },
  {
    name: 'Antarctica/DumontDUrville',
    offset: 10
  },
  {
    name: 'Antarctica/Macquarie',
    offset: 10
  },
  {
    name: 'Antarctica/Mawson',
    offset: 5
  },
  {
    name: 'Antarctica/McMurdo',
    offset: 12
  },
  {
    name: 'Antarctica/Palmer',
    offset: -3
  },
  {
    name: 'Antarctica/Rothera',
    offset: -3
  },
  {
    name: 'Antarctica/South_Pole',
    offset: 12
  },
  {
    name: 'Antarctica/Syowa',
    offset: 3
  },
  {
    name: 'Antarctica/Troll',
    offset: 0
  },
  {
    name: 'Antarctica/Vostok',
    offset: 6
  },
  {
    name: 'Arctic/Longyearbyen',
    offset: 1
  },
  {
    name: 'Asia/Aden',
    offset: 3
  },
  {
    name: 'Asia/Almaty',
    offset: 6
  },
  {
    name: 'Asia/Amman',
    offset: 2
  },
  {
    name: 'Asia/Anadyr',
    offset: 12
  },
  {
    name: 'Asia/Aqtau',
    offset: 5
  },
  {
    name: 'Asia/Aqtobe',
    offset: 5
  },
  {
    name: 'Asia/Ashgabat',
    offset: 5
  },
  {
    name: 'Asia/Ashkhabad',
    offset: 5
  },
  {
    name: 'Asia/Atyrau',
    offset: 5
  },
  {
    name: 'Asia/Baghdad',
    offset: 3
  },
  {
    name: 'Asia/Bahrain',
    offset: 3
  },
  {
    name: 'Asia/Baku',
    offset: 4
  },
  {
    name: 'Asia/Bangkok',
    offset: 7
  },
  {
    name: 'Asia/Barnaul',
    offset: 7
  },
  {
    name: 'Asia/Beirut',
    offset: 2
  },
  {
    name: 'Asia/Bishkek',
    offset: 6
  },
  {
    name: 'Asia/Brunei',
    offset: 8
  },
  {
    name: 'Asia/Calcutta',
    offset: 5
  },
  {
    name: 'Asia/Chita',
    offset: 9
  },
  {
    name: 'Asia/Choibalsan',
    offset: 8
  },
  {
    name: 'Asia/Chongqing',
    offset: 8
  },
  {
    name: 'Asia/Chungking',
    offset: 8
  },
  {
    name: 'Asia/Colombo',
    offset: 5
  },
  {
    name: 'Asia/Dacca',
    offset: 6
  },
  {
    name: 'Asia/Damascus',
    offset: 2
  },
  {
    name: 'Asia/Dhaka',
    offset: 6
  },
  {
    name: 'Asia/Dili',
    offset: 9
  },
  {
    name: 'Asia/Dubai',
    offset: 4
  },
  {
    name: 'Asia/Dushanbe',
    offset: 5
  },
  {
    name: 'Asia/Famagusta',
    offset: 2
  },
  {
    name: 'Asia/Gaza',
    offset: 2
  },
  {
    name: 'Asia/Harbin',
    offset: 8
  },
  {
    name: 'Asia/Hebron',
    offset: 2
  },
  {
    name: 'Asia/Ho_Chi_Minh',
    offset: 7
  },
  {
    name: 'Asia/Hong_Kong',
    offset: 8
  },
  {
    name: 'Asia/Hovd',
    offset: 7
  },
  {
    name: 'Asia/Irkutsk',
    offset: 8
  },
  {
    name: 'Asia/Istanbul',
    offset: 3
  },
  {
    name: 'Asia/Jakarta',
    offset: 7
  },
  {
    name: 'Asia/Jayapura',
    offset: 9
  },
  {
    name: 'Asia/Jerusalem',
    offset: 2
  },
  {
    name: 'Asia/Kabul',
    offset: 4
  },
  {
    name: 'Asia/Kamchatka',
    offset: 12
  },
  {
    name: 'Asia/Karachi',
    offset: 5
  },
  {
    name: 'Asia/Kashgar',
    offset: 6
  },
  {
    name: 'Asia/Kathmandu',
    offset: 5
  },
  {
    name: 'Asia/Katmandu',
    offset: 5
  },
  {
    name: 'Asia/Khandyga',
    offset: 9
  },
  {
    name: 'Asia/Kolkata',
    offset: 5
  },
  {
    name: 'Asia/Krasnoyarsk',
    offset: 7
  },
  {
    name: 'Asia/Kuala_Lumpur',
    offset: 8
  },
  {
    name: 'Asia/Kuching',
    offset: 8
  },
  {
    name: 'Asia/Kuwait',
    offset: 3
  },
  {
    name: 'Asia/Macao',
    offset: 8
  },
  {
    name: 'Asia/Macau',
    offset: 8
  },
  {
    name: 'Asia/Magadan',
    offset: 11
  },
  {
    name: 'Asia/Makassar',
    offset: 8
  },
  {
    name: 'Asia/Manila',
    offset: 8
  },
  {
    name: 'Asia/Muscat',
    offset: 4
  },
  {
    name: 'Asia/Nicosia',
    offset: 2
  },
  {
    name: 'Asia/Novokuznetsk',
    offset: 7
  },
  {
    name: 'Asia/Novosibirsk',
    offset: 7
  },
  {
    name: 'Asia/Omsk',
    offset: 6
  },
  {
    name: 'Asia/Oral',
    offset: 5
  },
  {
    name: 'Asia/Phnom_Penh',
    offset: 7
  },
  {
    name: 'Asia/Pontianak',
    offset: 7
  },
  {
    name: 'Asia/Pyongyang',
    offset: 9
  },
  {
    name: 'Asia/Qatar',
    offset: 3
  },
  {
    name: 'Asia/Qostanay',
    offset: 6
  },
  {
    name: 'Asia/Qyzylorda',
    offset: 5
  },
  {
    name: 'Asia/Rangoon',
    offset: 6
  },
  {
    name: 'Asia/Riyadh',
    offset: 3
  },
  {
    name: 'Asia/Saigon',
    offset: 7
  },
  {
    name: 'Asia/Sakhalin',
    offset: 11
  },
  {
    name: 'Asia/Samarkand',
    offset: 5
  },
  {
    name: 'Asia/Seoul',
    offset: 9
  },
  {
    name: 'Asia/Shanghai',
    offset: 8
  },
  {
    name: 'Asia/Singapore',
    offset: 8
  },
  {
    name: 'Asia/Srednekolymsk',
    offset: 11
  },
  {
    name: 'Asia/Taipei',
    offset: 8
  },
  {
    name: 'Asia/Tashkent',
    offset: 5
  },
  {
    name: 'Asia/Tbilisi',
    offset: 4
  },
  {
    name: 'Asia/Tehran',
    offset: 3
  },
  {
    name: 'Asia/Tel_Aviv',
    offset: 2
  },
  {
    name: 'Asia/Thimbu',
    offset: 6
  },
  {
    name: 'Asia/Thimphu',
    offset: 6
  },
  {
    name: 'Asia/Tokyo',
    offset: 9
  },
  {
    name: 'Asia/Tomsk',
    offset: 7
  },
  {
    name: 'Asia/Ujung_Pandang',
    offset: 8
  },
  {
    name: 'Asia/Ulaanbaatar',
    offset: 8
  },
  {
    name: 'Asia/Ulan_Bator',
    offset: 8
  },
  {
    name: 'Asia/Urumqi',
    offset: 6
  },
  {
    name: 'Asia/Ust-Nera',
    offset: 10
  },
  {
    name: 'Asia/Vientiane',
    offset: 7
  },
  {
    name: 'Asia/Vladivostok',
    offset: 10
  },
  {
    name: 'Asia/Yakutsk',
    offset: 9
  },
  {
    name: 'Asia/Yangon',
    offset: 6
  },
  {
    name: 'Asia/Yekaterinburg',
    offset: 5
  },
  {
    name: 'Asia/Yerevan',
    offset: 4
  },
  {
    name: 'Atlantic/Azores',
    offset: -1
  },
  {
    name: 'Atlantic/Bermuda',
    offset: -4
  },
  {
    name: 'Atlantic/Canary',
    offset: 0
  },
  {
    name: 'Atlantic/Cape_Verde',
    offset: -1
  },
  {
    name: 'Atlantic/Faeroe',
    offset: 0
  },
  {
    name: 'Atlantic/Faroe',
    offset: 0
  },
  {
    name: 'Atlantic/Jan_Mayen',
    offset: 1
  },
  {
    name: 'Atlantic/Madeira',
    offset: 0
  },
  {
    name: 'Atlantic/Reykjavik',
    offset: 0
  },
  {
    name: 'Atlantic/South_Georgia',
    offset: -2
  },
  {
    name: 'Atlantic/St_Helena',
    offset: 0
  },
  {
    name: 'Atlantic/Stanley',
    offset: -3
  },
  {
    name: 'Australia/ACT',
    offset: 10
  },
  {
    name: 'Australia/Adelaide',
    offset: 9
  },
  {
    name: 'Australia/Brisbane',
    offset: 10
  },
  {
    name: 'Australia/Broken_Hill',
    offset: 9
  },
  {
    name: 'Australia/Canberra',
    offset: 10
  },
  {
    name: 'Australia/Currie',
    offset: 10
  },
  {
    name: 'Australia/Darwin',
    offset: 9
  },
  {
    name: 'Australia/Eucla',
    offset: 8
  },
  {
    name: 'Australia/Hobart',
    offset: 10
  },
  {
    name: 'Australia/LHI',
    offset: 10
  },
  {
    name: 'Australia/Lindeman',
    offset: 10
  },
  {
    name: 'Australia/Lord_Howe',
    offset: 10
  },
  {
    name: 'Australia/Melbourne',
    offset: 10
  },
  {
    name: 'Australia/NSW',
    offset: 10
  },
  {
    name: 'Australia/North',
    offset: 9
  },
  {
    name: 'Australia/Perth',
    offset: 8
  },
  {
    name: 'Australia/Queensland',
    offset: 10
  },
  {
    name: 'Australia/South',
    offset: 9
  },
  {
    name: 'Australia/Sydney',
    offset: 10
  },
  {
    name: 'Australia/Tasmania',
    offset: 10
  },
  {
    name: 'Australia/Victoria',
    offset: 10
  },
  {
    name: 'Australia/West',
    offset: 8
  },
  {
    name: 'Australia/Yancowinna',
    offset: 9
  },
  {
    name: 'Brazil/Acre',
    offset: -5
  },
  {
    name: 'Brazil/DeNoronha',
    offset: -2
  },
  {
    name: 'Brazil/East',
    offset: -3
  },
  {
    name: 'Brazil/West',
    offset: -4
  },
  {
    name: 'CET',
    offset: 1
  },
  {
    name: 'CST6CDT',
    offset: -6
  },
  {
    name: 'Canada/Atlantic',
    offset: -4
  },
  {
    name: 'Canada/Central',
    offset: -6
  },
  {
    name: 'Canada/Eastern',
    offset: -5
  },
  {
    name: 'Canada/Mountain',
    offset: -7
  },
  {
    name: 'Canada/Newfoundland',
    offset: -3
  },
  {
    name: 'Canada/Pacific',
    offset: -8
  },
  {
    name: 'Canada/Saskatchewan',
    offset: -6
  },
  {
    name: 'Canada/Yukon',
    offset: -7
  },
  {
    name: 'Chile/Continental',
    offset: -4
  },
  {
    name: 'Chile/EasterIsland',
    offset: -6
  },
  {
    name: 'Cuba',
    offset: -5
  },
  {
    name: 'EET',
    offset: 2
  },
  {
    name: 'EST5EDT',
    offset: -5
  },
  {
    name: 'Egypt',
    offset: 2
  },
  {
    name: 'Eire',
    offset: 0
  },
  {
    name: 'Etc/GMT',
    offset: 0
  },
  {
    name: 'Etc/GMT+0',
    offset: 0
  },
  {
    name: 'Etc/GMT+1',
    offset: -1
  },
  {
    name: 'Etc/GMT+10',
    offset: -10
  },
  {
    name: 'Etc/GMT+11',
    offset: -11
  },
  {
    name: 'Etc/GMT+12',
    offset: -12
  },
  {
    name: 'Etc/GMT+2',
    offset: -2
  },
  {
    name: 'Etc/GMT+3',
    offset: -3
  },
  {
    name: 'Etc/GMT+4',
    offset: -4
  },
  {
    name: 'Etc/GMT+5',
    offset: -5
  },
  {
    name: 'Etc/GMT+6',
    offset: -6
  },
  {
    name: 'Etc/GMT+7',
    offset: -7
  },
  {
    name: 'Etc/GMT+8',
    offset: -8
  },
  {
    name: 'Etc/GMT+9',
    offset: -9
  },
  {
    name: 'Etc/GMT-0',
    offset: 0
  },
  {
    name: 'Etc/GMT-1',
    offset: 1
  },
  {
    name: 'Etc/GMT-10',
    offset: 10
  },
  {
    name: 'Etc/GMT-11',
    offset: 11
  },
  {
    name: 'Etc/GMT-12',
    offset: 12
  },
  {
    name: 'Etc/GMT-13',
    offset: 13
  },
  {
    name: 'Etc/GMT-14',
    offset: 14
  },
  {
    name: 'Etc/GMT-2',
    offset: 2
  },
  {
    name: 'Etc/GMT-3',
    offset: 3
  },
  {
    name: 'Etc/GMT-4',
    offset: 4
  },
  {
    name: 'Etc/GMT-5',
    offset: 5
  },
  {
    name: 'Etc/GMT-6',
    offset: 6
  },
  {
    name: 'Etc/GMT-7',
    offset: 7
  },
  {
    name: 'Etc/GMT-8',
    offset: 8
  },
  {
    name: 'Etc/GMT-9',
    offset: 9
  },
  {
    name: 'Etc/GMT0',
    offset: 0
  },
  {
    name: 'Etc/Greenwich',
    offset: 0
  },
  {
    name: 'Etc/UCT',
    offset: 0
  },
  {
    name: 'Etc/UTC',
    offset: 0
  },
  {
    name: 'Etc/Universal',
    offset: 0
  },
  {
    name: 'Etc/Zulu',
    offset: 0
  },
  {
    name: 'Europe/Amsterdam',
    offset: 1
  },
  {
    name: 'Europe/Andorra',
    offset: 1
  },
  {
    name: 'Europe/Astrakhan',
    offset: 4
  },
  {
    name: 'Europe/Athens',
    offset: 2
  },
  {
    name: 'Europe/Belfast',
    offset: 0
  },
  {
    name: 'Europe/Belgrade',
    offset: 1
  },
  {
    name: 'Europe/Berlin',
    offset: 1
  },
  {
    name: 'Europe/Bratislava',
    offset: 1
  },
  {
    name: 'Europe/Brussels',
    offset: 1
  },
  {
    name: 'Europe/Bucharest',
    offset: 2
  },
  {
    name: 'Europe/Budapest',
    offset: 1
  },
  {
    name: 'Europe/Busingen',
    offset: 1
  },
  {
    name: 'Europe/Chisinau',
    offset: 2
  },
  {
    name: 'Europe/Copenhagen',
    offset: 1
  },
  {
    name: 'Europe/Dublin',
    offset: 0
  },
  {
    name: 'Europe/Gibraltar',
    offset: 1
  },
  {
    name: 'Europe/Guernsey',
    offset: 0
  },
  {
    name: 'Europe/Helsinki',
    offset: 2
  },
  {
    name: 'Europe/Isle_of_Man',
    offset: 0
  },
  {
    name: 'Europe/Istanbul',
    offset: 3
  },
  {
    name: 'Europe/Jersey',
    offset: 0
  },
  {
    name: 'Europe/Kaliningrad',
    offset: 2
  },
  {
    name: 'Europe/Kiev',
    offset: 2
  },
  {
    name: 'Europe/Kirov',
    offset: 3
  },
  {
    name: 'Europe/Kyiv',
    offset: 2
  },
  {
    name: 'Europe/Lisbon',
    offset: 0
  },
  {
    name: 'Europe/Ljubljana',
    offset: 1
  },
  {
    name: 'Europe/London',
    offset: 0
  },
  {
    name: 'Europe/Luxembourg',
    offset: 1
  },
  {
    name: 'Europe/Madrid',
    offset: 1
  },
  {
    name: 'Europe/Malta',
    offset: 1
  },
  {
    name: 'Europe/Mariehamn',
    offset: 2
  },
  {
    name: 'Europe/Minsk',
    offset: 3
  },
  {
    name: 'Europe/Monaco',
    offset: 1
  },
  {
    name: 'Europe/Moscow',
    offset: 3
  },
  {
    name: 'Europe/Nicosia',
    offset: 2
  },
  {
    name: 'Europe/Oslo',
    offset: 1
  },
  {
    name: 'Europe/Paris',
    offset: 1
  },
  {
    name: 'Europe/Podgorica',
    offset: 1
  },
  {
    name: 'Europe/Prague',
    offset: 1
  },
  {
    name: 'Europe/Riga',
    offset: 2
  },
  {
    name: 'Europe/Rome',
    offset: 1
  },
  {
    name: 'Europe/Samara',
    offset: 4
  },
  {
    name: 'Europe/San_Marino',
    offset: 1
  },
  {
    name: 'Europe/Sarajevo',
    offset: 1
  },
  {
    name: 'Europe/Saratov',
    offset: 4
  },
  {
    name: 'Europe/Simferopol',
    offset: 3
  },
  {
    name: 'Europe/Skopje',
    offset: 1
  },
  {
    name: 'Europe/Sofia',
    offset: 2
  },
  {
    name: 'Europe/Stockholm',
    offset: 1
  },
  {
    name: 'Europe/Tallinn',
    offset: 2
  },
  {
    name: 'Europe/Tirane',
    offset: 1
  },
  {
    name: 'Europe/Tiraspol',
    offset: 2
  },
  {
    name: 'Europe/Ulyanovsk',
    offset: 4
  },
  {
    name: 'Europe/Uzhgorod',
    offset: 2
  },
  {
    name: 'Europe/Vaduz',
    offset: 1
  },
  {
    name: 'Europe/Vatican',
    offset: 1
  },
  {
    name: 'Europe/Vienna',
    offset: 1
  },
  {
    name: 'Europe/Vilnius',
    offset: 2
  },
  {
    name: 'Europe/Volgograd',
    offset: 3
  },
  {
    name: 'Europe/Warsaw',
    offset: 1
  },
  {
    name: 'Europe/Zagreb',
    offset: 1
  },
  {
    name: 'Europe/Zaporozhye',
    offset: 2
  },
  {
    name: 'Europe/Zurich',
    offset: 1
  },
  {
    name: 'GB',
    offset: 0
  },
  {
    name: 'GB-Eire',
    offset: 0
  },
  {
    name: 'GMT',
    offset: 0
  },
  {
    name: 'GMT0',
    offset: 0
  },
  {
    name: 'Greenwich',
    offset: 0
  },
  {
    name: 'Hongkong',
    offset: 8
  },
  {
    name: 'Iceland',
    offset: 0
  },
  {
    name: 'Indian/Antananarivo',
    offset: 3
  },
  {
    name: 'Indian/Chagos',
    offset: 6
  },
  {
    name: 'Indian/Christmas',
    offset: 7
  },
  {
    name: 'Indian/Cocos',
    offset: 6
  },
  {
    name: 'Indian/Comoro',
    offset: 3
  },
  {
    name: 'Indian/Kerguelen',
    offset: 5
  },
  {
    name: 'Indian/Mahe',
    offset: 4
  },
  {
    name: 'Indian/Maldives',
    offset: 5
  },
  {
    name: 'Indian/Mauritius',
    offset: 4
  },
  {
    name: 'Indian/Mayotte',
    offset: 3
  },
  {
    name: 'Indian/Reunion',
    offset: 4
  },
  {
    name: 'Iran',
    offset: 3
  },
  {
    name: 'Israel',
    offset: 2
  },
  {
    name: 'Jamaica',
    offset: -5
  },
  {
    name: 'Japan',
    offset: 9
  },
  {
    name: 'Kwajalein',
    offset: 12
  },
  {
    name: 'Libya',
    offset: 2
  },
  {
    name: 'MET',
    offset: 1
  },
  {
    name: 'MST7MDT',
    offset: -7
  },
  {
    name: 'Mexico/BajaNorte',
    offset: -8
  },
  {
    name: 'Mexico/BajaSur',
    offset: -7
  },
  {
    name: 'Mexico/General',
    offset: -6
  },
  {
    name: 'NZ',
    offset: 12
  },
  {
    name: 'NZ-CHAT',
    offset: 12
  },
  {
    name: 'Navajo',
    offset: -7
  },
  {
    name: 'PRC',
    offset: 8
  },
  {
    name: 'PST8PDT',
    offset: -8
  },
  {
    name: 'Pacific/Apia',
    offset: 13
  },
  {
    name: 'Pacific/Auckland',
    offset: 12
  },
  {
    name: 'Pacific/Bougainville',
    offset: 11
  },
  {
    name: 'Pacific/Chatham',
    offset: 12
  },
  {
    name: 'Pacific/Chuuk',
    offset: 10
  },
  {
    name: 'Pacific/Easter',
    offset: -6
  },
  {
    name: 'Pacific/Efate',
    offset: 11
  },
  {
    name: 'Pacific/Enderbury',
    offset: 13
  },
  {
    name: 'Pacific/Fakaofo',
    offset: 13
  },
  {
    name: 'Pacific/Fiji',
    offset: 12
  },
  {
    name: 'Pacific/Funafuti',
    offset: 12
  },
  {
    name: 'Pacific/Galapagos',
    offset: -6
  },
  {
    name: 'Pacific/Gambier',
    offset: -9
  },
  {
    name: 'Pacific/Guadalcanal',
    offset: 11
  },
  {
    name: 'Pacific/Guam',
    offset: 10
  },
  {
    name: 'Pacific/Honolulu',
    offset: -10
  },
  {
    name: 'Pacific/Johnston',
    offset: -10
  },
  {
    name: 'Pacific/Kanton',
    offset: 13
  },
  {
    name: 'Pacific/Kiritimati',
    offset: 14
  },
  {
    name: 'Pacific/Kosrae',
    offset: 11
  },
  {
    name: 'Pacific/Kwajalein',
    offset: 12
  },
  {
    name: 'Pacific/Majuro',
    offset: 12
  },
  {
    name: 'Pacific/Marquesas',
    offset: -9
  },
  {
    name: 'Pacific/Midway',
    offset: -11
  },
  {
    name: 'Pacific/Nauru',
    offset: 12
  },
  {
    name: 'Pacific/Niue',
    offset: -11
  },
  {
    name: 'Pacific/Norfolk',
    offset: 11
  },
  {
    name: 'Pacific/Noumea',
    offset: 11
  },
  {
    name: 'Pacific/Pago_Pago',
    offset: -11
  },
  {
    name: 'Pacific/Palau',
    offset: 9
  },
  {
    name: 'Pacific/Pitcairn',
    offset: -8
  },
  {
    name: 'Pacific/Pohnpei',
    offset: 11
  },
  {
    name: 'Pacific/Ponape',
    offset: 11
  },
  {
    name: 'Pacific/Port_Moresby',
    offset: 10
  },
  {
    name: 'Pacific/Rarotonga',
    offset: -10
  },
  {
    name: 'Pacific/Saipan',
    offset: 10
  },
  {
    name: 'Pacific/Samoa',
    offset: -11
  },
  {
    name: 'Pacific/Tahiti',
    offset: -10
  },
  {
    name: 'Pacific/Tarawa',
    offset: 12
  },
  {
    name: 'Pacific/Tongatapu',
    offset: 13
  },
  {
    name: 'Pacific/Truk',
    offset: 10
  },
  {
    name: 'Pacific/Wake',
    offset: 12
  },
  {
    name: 'Pacific/Wallis',
    offset: 12
  },
  {
    name: 'Pacific/Yap',
    offset: 10
  },
  {
    name: 'Poland',
    offset: 1
  },
  {
    name: 'Portugal',
    offset: 0
  },
  {
    name: 'ROK',
    offset: 9
  },
  {
    name: 'Singapore',
    offset: 8
  },
  {
    name: 'SystemV/AST4',
    offset: -4
  },
  {
    name: 'SystemV/AST4ADT',
    offset: -4
  },
  {
    name: 'SystemV/CST6',
    offset: -6
  },
  {
    name: 'SystemV/CST6CDT',
    offset: -6
  },
  {
    name: 'SystemV/EST5',
    offset: -5
  },
  {
    name: 'SystemV/EST5EDT',
    offset: -5
  },
  {
    name: 'SystemV/HST10',
    offset: -10
  },
  {
    name: 'SystemV/MST7',
    offset: -7
  },
  {
    name: 'SystemV/MST7MDT',
    offset: -7
  },
  {
    name: 'SystemV/PST8',
    offset: -8
  },
  {
    name: 'SystemV/PST8PDT',
    offset: -8
  },
  {
    name: 'SystemV/YST9',
    offset: -9
  },
  {
    name: 'SystemV/YST9YDT',
    offset: -9
  },
  {
    name: 'Turkey',
    offset: 3
  },
  {
    name: 'UCT',
    offset: 0
  },
  {
    name: 'US/Alaska',
    offset: -9
  },
  {
    name: 'US/Aleutian',
    offset: -10
  },
  {
    name: 'US/Arizona',
    offset: -7
  },
  {
    name: 'US/Central',
    offset: -6
  },
  {
    name: 'US/East-Indiana',
    offset: -5
  },
  {
    name: 'US/Eastern',
    offset: -5
  },
  {
    name: 'US/Hawaii',
    offset: -10
  },
  {
    name: 'US/Indiana-Starke',
    offset: -6
  },
  {
    name: 'US/Michigan',
    offset: -5
  },
  {
    name: 'US/Mountain',
    offset: -7
  },
  {
    name: 'US/Pacific',
    offset: -8
  },
  {
    name: 'US/Samoa',
    offset: -11
  },
  {
    name: 'UTC',
    offset: 0
  },
  {
    name: 'Universal',
    offset: 0
  },
  {
    name: 'W-SU',
    offset: 3
  },
  {
    name: 'WET',
    offset: 0
  },
  {
    name: 'Zulu',
    offset: 0
  },
  {
    name: 'EST',
    offset: -5
  },
  {
    name: 'HST',
    offset: -10
  },
  {
    name: 'MST',
    offset: -7
  },
  {
    name: 'ACT',
    offset: 9
  },
  {
    name: 'AET',
    offset: 10
  },
  {
    name: 'AGT',
    offset: -3
  },
  {
    name: 'ART',
    offset: 2
  },
  {
    name: 'AST',
    offset: -9
  },
  {
    name: 'BET',
    offset: -3
  },
  {
    name: 'BST',
    offset: 6
  },
  {
    name: 'CAT',
    offset: 2
  },
  {
    name: 'CNT',
    offset: -3
  },
  {
    name: 'CST',
    offset: -6
  },
  {
    name: 'CTT',
    offset: 8
  },
  {
    name: 'EAT',
    offset: 3
  },
  {
    name: 'ECT',
    offset: 1
  },
  {
    name: 'IET',
    offset: -5
  },
  {
    name: 'IST',
    offset: 5
  },
  {
    name: 'JST',
    offset: 9
  },
  {
    name: 'MIT',
    offset: 13
  },
  {
    name: 'NET',
    offset: 4
  },
  {
    name: 'NST',
    offset: 12
  },
  {
    name: 'PLT',
    offset: 5
  },
  {
    name: 'PNT',
    offset: -7
  },
  {
    name: 'PRT',
    offset: -4
  },
  {
    name: 'PST',
    offset: -8
  },
  {
    name: 'SST',
    offset: 11
  },
  {
    name: 'VST',
    offset: 7
  }
];
